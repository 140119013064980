<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Approval Required')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Process Category')" prop="category">
            <el-select popper-class="category-select" v-model="searchForm.category" placeholder="Select" clearable>
              <el-option
                v-for="category in categories"
                :key="category.code"
                :label="category.name"
                :value="category.code">
                <div style="display: flex;align-items: center">
                  <tw-entity-icon :entityName="category.entityId" size="medium" color="color" style="margin-right: 8px;" />
                  <span>{{category.name}}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="process">
            <el-cascader popper-class="category-select" v-model="searchForm.process" placeholder="Select" :options="processes" :props="{value: 'code', 'label': 'name'}" :filter-method="filter" clearable filterable>
              <template slot-scope="{ data }">
                <div style="display: flex;align-items: center">
                  <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
                  <span>{{data.name}}</span>
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
            <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
            <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Approver/Deputy Approver')" prop="approver">
            <el-select v-model="searchForm.approver" placeholder="Select" clearable>
              <el-option
                v-for="approver in APPROVER_PIC_VARIABLES"
                :key="approver.code"
                :label="approver.label"
                :value="approver.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Applicant')" prop="applicant">
            <el-select v-model="searchForm.applicant" placeholder="Select" clearable filterable>
              <el-option
                v-for="applicant in applicantList"
                :key="applicant.applicantUserId"
                :label="applicant.applicantUserName"
                :value="applicant.applicantUserId">
              </el-option>
            </el-select>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Approval Date')" prop="approvalDate">
                <el-date-picker
                  v-model="searchForm.approvalDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Application Date')" prop="applicationDate">
                <el-date-picker
                  v-model="searchForm.applicationDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
                <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Approval Status')" prop="approvalStatus" style="margin-right: 40px">
                <el-select v-model="searchForm.approvalStatus" placeholder="Select" clearable>
                  <el-option
                    v-for="status in AUTHORIZATION_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { APPROVE_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, APPROVER_PIC_VARIABLES, AUTHORIZATION_STATUS_VARIABLES, PROCESS_TYPE, AUTHORIZATION_STATUS } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { collapseTransition } from '@/utils/nextFrame';
import { entityNames, processNames, } from '@/dictionaries/map.js';
import { normalize, clearSearchForm } from '@/utils/searchUtil';
import schemas from '@/dictionaries/listApprovalSchema.json';

export default {
  name: 'ListApproval',
  mixins: [mixinEntityList, collapseTransition],
  data() {
    return {
      schemas: schemas,
      items: null,
      // エンティティプルダウン
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      // プロセスプルダウン（承認プロセスのみ）
      processes: [],
      applicantList: [],
      // 承認者プルダウン
      APPROVER_PIC_VARIABLES: APPROVER_PIC_VARIABLES,
      // 承認ステータスプルダウン
      AUTHORIZATION_STATUS_VARIABLES: AUTHORIZATION_STATUS_VARIABLES,
      searchForm: {
        category: '',
        process: [],
        fromSectionIds: [],
        toSectionIds: [],
        approver: '',
        applicant: '',
        approvalDate: '',
        applicationDate: '',
        tradingName: '',
        approvalStatus: AUTHORIZATION_STATUS.AWAITING_APPROVAL,
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Approval Required')},
      ];
    },
  },
  created() {
    this.processes = _.reject(_.map(this.categories, o => {
      const children = _.map(_.filter(processNames, {parent: o.entityId, approval: true}), p => {
        return Object.assign(p, {code: p.processType, name: this.$t(`Process.${p.processType.replace('PT_', '').toUpperCase()}`)});
      });
      return Object.assign({children: children}, o);
    }), o => _.isEmpty(o.children));

    const queries = this.$store.getters.getQueries('APPROVAL');
    if (queries) {
      this.searchForm = queries;
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const processType = _.get(f.process, '1');
      // Booking依頼プロセスを選択時にCategoryが未選択だったらクエリに選択したBooking依頼のCategoryを指定する
      let processCategory = f.category;
      if (processType === PROCESS_TYPE.TPATPRB && !processCategory) {
        processCategory = _.get(f.process, '0');
      }

      const queries = {
        processCategory: processCategory,
        processType: processType,
        fromSectionId: f.fromSectionIds,
        toSectionId: f.toSectionIds,
        mainFlg: f.approver,
        createUserName: f.applicant,
        approvalStartDate: this.getStartDate(f.approvalDate),
        approvalEndDate: this.getEndDate(f.approvalDate),
        applicationStartDate: this.getStartDate(f.applicationDate),
        applicationEndDate: this.getEndDate(f.applicationDate),
        tradingName: f.tradingName,
        approvalStatus: f.approvalStatus,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      const params = {
        // bff_ap_1 承認一覧検索BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/approvers/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      // Booking依頼プロセスを選択時に違うCategoryを選択していたらリクエストせずに検索結果を空にする
      if (params.lslConfig.query.processType === PROCESS_TYPE.TPATPRB && params.lslConfig.query.processCategory !== _.get(this.searchForm.process, '0')) {
        this.items = [];
        return;
      }

      $api.request(params)
      .then(res => {
        this.items = res.approvalList;
        this.applicantList = res.applicantList;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.$store.commit('SET_QUERIES', {key: 'APPROVAL', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.searchForm.approvalStatus = AUTHORIZATION_STATUS.AWAITING_APPROVAL,
      this.$store.commit('SET_QUERIES', {key: 'APPROVAL', queries: _.cloneDeep(this.searchForm)});
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [APPROVE_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_ap_1 承認一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/approvers/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.approvalList);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
